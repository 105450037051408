import {
  FarmtrxIconHardware,
  FarmtrxIconHarvesterCombine,
  // FarmtrxIconUsers,
  FarmtrxIconCrop,
  FarmtrxIconSettings,
} from '@troo/farmtrx-icons'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CommonSidebar, CommonSidebarLink } from '../common-sidebar'
import CropsComponent from './crops'
import EquipmentsComponent from './equipment'
import HardwareComponent from './hardware'
import CropHeadersComponent from './headers'
// import UserAccountsComponent from './user-accounts'
import Divider from '@mui/material/Divider'
import PreferencesComponent from 'components/admin/preferences'

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', overflowY: 'auto' },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: 'auto',
  },
}))

export default function FarmSettings() {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.root} width={1}>
      <CommonSidebar>
        <CommonSidebarLink
          to="hardware"
          icon={
            <FarmtrxIconHardware
              className={classes.navIcons}
              title={t('hardware')}
            />
          }
          label={t('hardware')}
        />
        <Divider />
        <CommonSidebarLink
          to="combines"
          icon={
            <FarmtrxIconHarvesterCombine
              className={classes.navIcons}
              title={t('combines')}
            />
          }
          label={t('combines')}
        />
        <Divider />
        <CommonSidebarLink
          to="headers"
          icon={
            <FarmtrxIconHarvesterCombine
              className={classes.navIcons}
              title={t('headers')}
            />
          }
          label={t('headers')}
        />
        <Divider />
        {/* <CommonSidebarLink
          to="userAccounts"
          icon={
            <FarmtrxIconUsers
              className={classes.navIcons}
              title={t('user_accounts')}
            />
          }
          label={t('user_accounts')}
        />
        <Divider /> */}
        <CommonSidebarLink
          to="crops"
          icon={
            <FarmtrxIconCrop
              className={classes.navIcons}
              title={t('crops_varieties')}
            />
          }
          label={t('crops_varieties')}
        />
        <Divider />
        <CommonSidebarLink
          key="preferences"
          to="preferences"
          icon={
            <FarmtrxIconSettings
              className={classes.navIcons}
              title={t('preferences')}
            />
          }
          label={t('preferences')}
        />
        <Divider />
      </CommonSidebar>
      <Routes>
        <Route
          exact
          path="/"
          element={<Navigate to="/farmSetup/hardware" replace={true} />}
        />

        <Route path="/crops" element={<CropsComponent />} />
        <Route path="/headers" element={<CropHeadersComponent />} />
        <Route path="/combines" element={<EquipmentsComponent />} />
        <Route path="/hardware" element={<HardwareComponent />} />
        {/* <Route path="/userAccounts" element={<UserAccountsComponent />} /> */}
        <Route path="/preferences" element={<PreferencesComponent />} />
      </Routes>
    </Box>
  )
}
