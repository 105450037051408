import * as c from 'common/c'

export const organizationsFetched = (data) => {
  return {
    type: c.ORGANIZATIONS_FETCHED,
    payload: data,
  }
}

export const userFetched = (data) => {
  return {
    type: c.USER_FETCHED,
    payload: data,
  }
}

export const eusaAccepted = (data) => {
  return {
    type: c.EUSA_ACCEPTED,
    payload: data,
  }
}

export const updateClient = (data) => {
  return {
    type: c.CLIENT_UPDATED,
    payload: { data },
  }
}

export const contactInfoFetched = (data) => {
  return {
    type: c.CONTACT_INFO_FETCHED,
    payload: data,
  }
}
