import * as c from 'common/c'

import { get, findIndex, cloneDeep } from 'lodash-es'

const initialState = {
  singleton: {},
  options: {},
  editTenantBool: false,
  auth: {
    isAuthenticated: false,
    isLoading: false,
  },
  hasToken: false,
  hasTokenWithPermissionsRetries: 0,
  organizations: [],
  clients: [],
  permissions: [],
  token: undefined,
  localState: {},
  accessibleAccounts: [],
  invitedUsers: [],
  user: {},
  partnerContactDetails: {},
  subscriptions: [],
  currentSubscription: {},
  customClaims: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.TENANT_FETCHED: {
      return update(state, { singleton: { $set: action.payload } })
    }

    case c.EDIT_TENANT_BOOL: {
      return update(state, { editTenantBool: { $set: action.payload } })
    }

    case c.OPTIONS_FETCHED: {
      return update(state, { options: { $set: action.payload } })
    }

    case c.AUTH_DATA: {
      return update(state, { auth: { $set: action.payload } })
    }

    case c.HAS_TOKEN: {
      return update(state, { hasToken: { $set: true } })
    }

    case c.PERMISSIONS: {
      return update(state, { permissions: { $set: action.payload } })
    }

    case c.SET_CUSTOM_CLAIMS: {
      return update(state, { customClaims: { $set: action.payload } })
    }

    case c.HAS_TOKEN_WITH_PERMISSIONS_RETRIES: {
      return update(state, {
        hasTokenWithPermissionsRetries: { $set: action.payload },
      })
    }

    case c.ORGANIZATIONS_FETCHED: {
      return update(state, { organizations: { $set: action.payload } })
    }

    case c.CLIENTS_FETCHED: {
      return update(state, { clients: { $set: action.payload } })
    }

    case c.SET_TOKEN: {
      return update(state, { token: { $set: action.payload } })
    }

    case c.SET_LOCAL_TENANT_FORM_DATA: {
      return update(state, { localState: { $set: action.payload } })
    }

    case c.ACCESSIBLE_ACCOUNTS_FETCHED: {
      return update(state, { accessibleAccounts: { $set: action.payload } })
    }

    case c.INVITED_USERS_FETCHED: {
      return update(state, { invitedUsers: { $set: action.payload } })
    }

    case c.USER_FETCHED: {
      return update(state, { user: { $set: action.payload } })
    }

    case c.EUSA_ACCEPTED: {
      return update(state, { user: { eusaDate: { $set: action.payload } } })
    }

    case c.EDIT_USER_ACCOUNT: {
      return update(state, { user: { $set: action.payload } })
    }

    case c.CLIENT_UPDATED: {
      const organizations = get(state, 'organizations', [])
      const { data } = action.payload

      const index = findIndex(
        organizations,
        (organization) => organization.tenantId === data.id,
      )
      if (index > -1) {
        const organizationData = {
          accountEnabled: data.accountEnabled,
          tenantId: data.id,
          email: data.email,
          fullName: data.fullName,
          phoneNumber: data.phoneNumber,
          organizationName:
            data.organizationName ?? data.displayName ?? data.id,
        }
        return update(state, {
          organizations: { $splice: [[index, 1, organizationData]] },
        })
      } else return
    }

    case c.CONTACT_INFO_FETCHED: {
      return update(state, { partnerContactDetails: { $set: action.payload } })
    }

    case c.SUBSCRIPTIONS_FETCHED: {
      return update(state, { subscriptions: { $set: action.payload } })
    }

    case c.HARVESTED_AREA_FETCHED: {
      const data = action.payload
      let subscriptions = cloneDeep(state.subscriptions)
      let period = subscriptions[0]['SubscriptionPeriods'].find(
        (p) => p.startDate === data.startDate && p.endDate === data.endDate,
      )
      period['harvestedArea'] = data.harvestedAreaSqM
      return update(state, { subscriptions: { $set: subscriptions } })
    }

    case c.SUBSCRIPTION_PAYMENT_LINK_FETCHED: {
      const { paymentLink, startDate, endDate } = action.payload
      let subscriptions = cloneDeep(state.subscriptions)
      let period = subscriptions[0]['SubscriptionPeriods'].find(
        (p) => p.startDate === startDate && p.endDate === endDate,
      )
      period['paymentLink'] = paymentLink
      return update(state, { subscriptions: { $set: subscriptions } })
    }

    case c.PAYMENT_INFO_FETCHED: {
      const { id, payload } = action.payload
      let period
      let subscriptions = cloneDeep(state.subscriptions)
      period = subscriptions[0]['SubscriptionPeriods'].find((p) => p.id === id)
      period['paymentInfo'] = payload
      return update(state, { subscriptions: { $set: subscriptions } })
    }

    case c.CURRENT_SUBSCRIPTION_FETCHED: {
      return update(state, { currentSubscription: { $set: action.payload } })
    }

    case c.SUBSCRIPTION_PAYMENT_FETCHED: {
      return update(state, {
        subscriptionPaymentFetched: { $set: action.payload },
      })
    }

    default:
      return state
  }
}
