import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import { showModal } from 'actions/app'
import { get, noop, filter, find, sumBy } from 'lodash-es'
import { DataGrid } from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { fromGeoJson } from 'common/ol/utils'
import { getArea } from 'ol/sphere.js'
import * as c from 'common/c'
import { convertAreaToPref } from 'common/unitConvert'
import OCFFAddFarmComponent from './add-farm'
import OCFFDeleteFarm from './delete-farm'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState, ReactElement } from 'react'
import type { AppState } from 'state'
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined'
import OCFFAssignFarmsComponent from './assign-farms-to-clients'

const useStyles = makeStyles(() => ({
  borderedBox: {
    border: '1px solid #003057',
    borderRadius: '2px',
  },
  dataGridContainer: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    height: '100%',
  },
  dataGridHeader: {
    background: '#f8f8f9',
    textTransform: 'uppercase',
  },
}))

declare module 'components/ocff/farms' {
  type featuresOverviewType = {
    id: number
    geometry: object
    properties: object
  }

  type fieldAreaMapType = {
    id: number
    area: number
    farmId: number
  }

  type RowData = {
    id: number
    fieldArea: string | 0
    name: string | ''
    fieldsQuantity: number | 0
    clientName: string | ''
  }

  type farmColumns = {
    field: string
    headerName: string
    minWidth: number
    maxWidth: number
    flex: number
  }
}
export default function OCFFFarmsComponent(): ReactElement<any, any> {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const fields = useSelector((state) => get(state, 'field.collection' || []))
  const farms = useSelector((state) =>
    get(state as AppState, 'farm.collection' || []),
  )
  const clients = useSelector((state) =>
    get(state as AppState, 'client.collection' || []),
  )
  const userPrefs = useSelector((state) =>
    get(state as AppState, 'account.singleton.prefs'),
  )
  const [selectionModel, setSelectionModel] = useState<number[]>([])
  const [searchValue, setSearchValue] = useState('')

  const permissions = useSelector((state) =>
    get(state as AppState, 'account.permissions'),
  )

  const showFarms = permissions.includes('farm:read')
  const addModifyFarms = permissions.includes('farm:write')

  const [columns, setColumns] = useState<farmColumns[]>([])
  const [fieldAreaMap, setFieldAreaMap] = useState<fieldAreaMapType[]>([])

  const [columnVisibility, setColumnVisibility] = useState({
    fullName: true,
  })
  const featuresOverviewArr = useSelector(
    (state) => state['field']['featuresOverview'],
  )
  const [rows, setRows] = useState<RowData[]>([])

  useEffect(() => {
    const _fieldAreaMap: fieldAreaMapType[] = []
    const allFeaturesArr: featuresOverviewType[] = []
    if (featuresOverviewArr && featuresOverviewArr !== c.UNDEFINED) {
      featuresOverviewArr.forEach((item) => {
        allFeaturesArr.push(Object.assign({}, { type: 'Feature' }, { ...item }))
      })
    }
    const featureCollection = fromGeoJson({
      type: 'FeatureCollection',
      features: allFeaturesArr,
    })

    featureCollection.forEach(function (feature) {
      const id = feature.getId()
      //to get farmId
      let farmId = find(fields, ['id', id])?.['farmId']
      //end
      let area = 0
      area = getArea(feature.getGeometry())
      _fieldAreaMap.push({
        id,
        area: Math.floor(area),
        farmId,
      })
    })
    setFieldAreaMap(_fieldAreaMap)
  }, [featuresOverviewArr])

  const onSearch = (e) => {
    setSearchValue(e.target.value)
  }

  const filterFarms = (searchValue, farm) => {
    if (searchValue) {
      const lowerSearchValue = searchValue.toLowerCase()
      return farm.name.toLowerCase().indexOf(lowerSearchValue) > -1
    }
    return true
  }

  const showAddFarmPopup = useCallback(() => {
    dispatch(showModal(<OCFFAddFarmComponent isEditOrAdd="add" />))
  }, [])

  useEffect(() => {
    const _columns: farmColumns[] = [
      {
        field: 'name',
        headerName: t('farm'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
      {
        field: 'clientName',
        headerName: t('client'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
      {
        field: 'fieldsQuantity',
        headerName: t('fields_quantity'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
      {
        field: 'fieldArea',
        headerName: t('field_area'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
    ]
    setColumns(_columns)
    setSelectionModel([])
  }, [clients, farms])

  function CustomNoRowsOverlayFields(): ReactElement {
    if (showFarms) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {t('no_farms')}
        </div>
      )
    } else
      return (
        <div style={{ textAlign: 'center', padding: '32px' }}>
          {t('no_permissions', { item: t('clients') })}
        </div>
      )
  }

  const getClientName = useCallback(
    (id) => {
      const name = find(clients, ['id', id])?.['name']
      return name
    },
    [farms, clients],
  )

  useEffect(() => {
    let _rows: RowData[] = []
    if (showFarms) {
      farms.forEach((farm) => {
        let totalFields = 0,
          fieldArea = 0,
          matchingObjs: fieldAreaMapType[] = []
        totalFields = filter(fields, ['farmId', farm.id]).length + totalFields
        matchingObjs = fieldAreaMap.length
          ? filter(fieldAreaMap, ['farmId', farm.id])
          : []
        fieldArea = sumBy(matchingObjs, 'area')

        _rows.push({
          id: farm.id,
          fieldArea:
            `${Math.floor(
              convertAreaToPref(fieldArea, userPrefs?.['units']['area_in']),
            )} ${userPrefs?.['units']['area_in']}` ?? 0,
          name: farm.name,
          clientName: getClientName(farm['clientId']) ?? '',
          fieldsQuantity: totalFields ?? 0,
        })
      })
      setRows(_rows)
    } else {
      setRows([])
    }
  }, [clients, fieldAreaMap, fields, farms])

  const editFarm = useCallback(() => {
    if (selectionModel.length) {
      const selectedFarm = find(rows, ['id', selectionModel[0]])
      dispatch(
        showModal(
          <OCFFAddFarmComponent
            isEditOrAdd="edit"
            selectedFarm={selectedFarm}
          />,
        ),
      )
    }
  }, [selectionModel])

  const deleteFarm = useCallback(() => {
    if (selectionModel.length) {
      const selectedFarm = find(rows, ['id', selectionModel[0]])
      dispatch(showModal(<OCFFDeleteFarm selectedFarm={selectedFarm} />))
    }
  }, [selectionModel])

  const assignFarms = useCallback(() => {
    const selectedFarms = []
    selectionModel.map((s) => {
      let obj = find(farms, ['id', s])
      //@ts-ignore
      selectedFarms.push(obj)
    })
    dispatch(
      showModal(
        <OCFFAssignFarmsComponent
          selectedFarms={selectedFarms}
          selectedIds={selectionModel}
        />,
      ),
    )
  }, [selectionModel])

  const onClick = noop

  return (
    <>
      <Box px={3} py={4} width={1} className={classes.pageContainer}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h2>{t('farms')}</h2>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              style={{ display: addModifyFarms ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              disabled={
                selectionModel.length === 0 || selectionModel.length > 1
              }
              onClick={deleteFarm}
              title={t('delete_item', { item: t('farm') })}
            >
              <DeleteOutlineOutlinedIcon
                style={{
                  color: selectionModel.length === 1 ? '#003057' : 'gray',
                }}
              />
            </IconButton>

            <IconButton
              style={{ display: addModifyFarms ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              title={t('assign_farms_to_client')}
              onClick={assignFarms}
              disabled={
                selectionModel.length === 0 ||
                (selectionModel.length > 1 && farms.length === 1)
              }
            >
              <DynamicFeedOutlinedIcon
                style={{ color: selectionModel.length ? '#003057' : 'gray' }}
              />
            </IconButton>

            <IconButton
              style={{ display: addModifyFarms ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              title={t('edit_farm')}
              disabled={
                selectionModel.length === 0 || selectionModel.length > 1
              }
              onClick={editFarm}
            >
              <EditIcon
                style={{
                  color: selectionModel.length === 1 ? '#003057' : 'gray',
                }}
              />
            </IconButton>

            <IconButton
              style={{ display: addModifyFarms ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              onClick={showAddFarmPopup}
              title={t('add_farm')}
            >
              <AddIcon style={{ color: '#003057' }} />
            </IconButton>
            <TextField
              id="search"
              onChange={(val) => {
                onSearch(val)
              }}
              type="search"
              placeholder="Search"
              hiddenLabel
              sx={{ marginLeft: '10px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box my={1} className={classes.pageContainer}>
          <div className={classes.dataGridContainer}>
            <DataGrid
              checkboxSelection
              onSelectionModelChange={(selection: []) => {
                setSelectionModel(selection)
              }}
              autoHeight
              selectionModel={selectionModel}
              columnVisibilityModel={columnVisibility}
              onColumnVisibilityModelChange={(model: { fullName: true }) =>
                setColumnVisibility(model)
              }
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
              }}
              components={{
                NoRowsOverlay: CustomNoRowsOverlayFields,
              }}
              rows={rows.filter((farm) => filterFarms(searchValue, farm))}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              onRowClick={(params) => onClick(params.row)}
              classes={{ columnHeader: classes.dataGridHeader }}
            />
          </div>
        </Box>
      </Box>
    </>
  )
}
